.offerCards {
  width: 18rem;
  height: 300px;
  font-size: 15px;
  color: white;
  text-align: center;
  border-radius: 5%;
  margin: auto;
  background-color: #343434 !important;
  -webkit-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
}

.card-iconx > img {
  text-align: center;

  color: goldenrod;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1350px) {
  .offerCards {
    width: 18rem;
  }

  .card-icon {
    font-size: inherit;
  }
}

@media screen and (min-device-width: 1080px) and (max-device-width: 1199px) {
  .offerCards {
    width: 15rem;
  }
  .offername {
    font-size: 16px;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1080px) {
  .offerCards {
    width: 15rem;
    font-size: 13px;
  }
  .offername {
    font-size: 16px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .offerCards {
    width: 13rem;
  }

  .card-icon > img {
    width: 70px;
    height: 80px;
  }

  .offername {
    font-size: 16px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 430px) {
  .offerCards {
    width: 15rem !important;
    height: 250px;
    margin: auto !important;
  }
  .col-card {
    padding: 10px;
    justify-content: center;
    display: flex;
    align-content: center;
  }

  .cover4-card1 {
    padding: 20px;
    margin: auto;
  }

  .offername {
    font-size: 16px;
  }

  .card-iconx > img {
    width: 50%;
    height: 70%;
  }
}

@media screen and (min-device-width: 431px) and (max-device-width: 767px) {
  .offerCards {
    width: 25rem !important;
    margin: auto;
  }
  .col-card {
    padding: 40px;
    justify-content: center;
    display: flex;
    align-content: center;
  }
}

.OfferBox {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;

  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);

  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30);
  -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
  zoom: 1;

  padding: 40px;
}

/* Custom listing */

ul {
  padding-top: 10px;
  list-style: none;
  font-size: 15px;
  list-style-type: circle;
}

li {
  font-family: "Fira Sans", sans-serif;
  font-size: medium;
  padding: 5px;
}

ul li:before {
  font-weight: bold;
  padding-right: 10px;
}

.animation {
  width: 100%;
  height: 100%;
}

.animation-2 {
  width: 130%;
  height: 130%;
}

@media screen and (min-device-width: 0px) and (max-device-width: 991px) {
  .animation {
    width: 65%;
    height: 65%;
    margin: auto;
  }

  .animation-2 {
    width: 85%;
    height: 85%;
    margin: auto;
  }
}
