/* To avoid overflow in small devices */
@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }
  .container {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
}

@media only screen and (max-width: 1920px) {
  .cover4-card1 {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
}

/* App.js classes */

.top {
  background-image: url("./assets/ns.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  width: 100%;
  height: 1080px;
}

.basic {
  padding-top: 20px;
  margin: auto;
}

/* Navbar styling */

.nav-link {
  font-family: "Roboto", sans-serif;
  padding: 15px;
}

.nav-link:hover {
  color: goldenrod !important ;
}

.active {
  color: goldenrod !important;
}

#nav-icon1 {
  width: 35px;
  height: 5px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: white;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Logo */

@media screen and (min-device-width: 500px) and (max-device-width: 3000px) {
  .logo2 {
    display: none;
  }
}
@media screen and (min-device-width: 500px) and (max-device-width: 991px) {
  .logo1 {
    text-align: center;
    padding-top: 30px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 499px) {
  .logo1 {
    display: none;
  }

  .logo2 {
    text-align: center;
    padding: 20px;
  }
}

/* Right graphic image on Banner */

.banner1 {
  float: right;
}

@media screen and (min-device-width: 0px) and (max-device-width: 1376px) {
  .banner1 {
    display: none;
  }
}

/* Text Over Banner */

.banner-text {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
  color: white;
  font-size: 28px;
  padding-top: 10px;
}

/* Custom Button Styling */

.btn-hover {
  width: 200px;
  font-size: 26px;
  font-weight: bold;
  color: #34343e;
  cursor: pointer;
  height: 55px;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover-verified {
  width: 200px;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 55px;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover-4 {
  font-family: "Montserrat", sans-serif;
  width: 230px;
  font-size: 24px;
  color: #34343e;
  cursor: pointer;
  height: 75px;
  border: none;
  background-size: 300% 100%;

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media screen and (min-device-width: 0px) and (max-device-width: 767px) {
  .buttons-small {
    text-align: center;
  }
  .btn-hover-4 {
    width: 70vw;
    font-size: 22px;
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 991px) {
  .buttons-small {
    text-align: center;
  }
  .btn-hover-4 {
    font-size: 22px;
    width: 330px;
  }
}

.btn-hover:hover {
  background-position: 100% 0;

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover-verified:hover {
  background-position: 100% 0;

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover-4:hover {
  background-position: 100% 0;
  background-image: linear-gradient(
    90deg,
    #c88b3f,
    #fbf5b7,
    #bf953f,
    #fbf5b7,
    #c88b3f,
    #c88b3f
  );

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover-verified:focus {
  outline: none;
}

.btn-hover-4:focus {
  outline: none;
}

.btn-hover.color-main {
  background-image: linear-gradient(
    90deg,
    #c88b3f,
    #fbf5b7,
    #bf953f,
    #fbf5b7,
    #c88b3f,
    #c88b3f
  );
}

.btn-hover-verified.color-main {
  background: linear-gradient(to right, #0575e6, #0575e6, #205e9b);
}

.btn-hover-4.color-main {
  background-color: #eae8e8;
}

.cover2 {
  color: black;
  text-align: center;
}

.cover2-para {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #9c9ca8;
}

.cover5-para {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #9c9ca8;
}

@media screen and (min-device-width: 0px) and (max-device-width: 991px) {
  .banner-text {
    text-align: center;
    color: white;
    text-shadow: 1px 1px 1px #000, 3px 3px 5px grey;
    font-size: 18px;
  }

  .btn-hover {
    width: 150px;
    font-size: 20px;
  }

  .cover2-para {
    text-align: left;
    font-size: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.cover3 {
  padding-left: 150px;
}

.cover3-para {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #9c9ca8;
}
.cover3-image1 {
  text-align: center;
}

@media screen and (min-device-width: 0px) and (max-device-width: 1220px) {
  .cover3-image1 > img {
    text-align: center;
    width: 300px !important;
    height: 400px !important;
  }
}

@media screen and (min-device-width: 491px) and (max-device-width: 3000px) {
  .cover3-image2 {
    text-align: center;
    display: none;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .cover3-para {
    font-size: 17px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 991px) {
  .cover3 {
    padding-top: 40px;
    padding-left: 0;
    text-align: center;
    margin-left: 7px;
    margin-right: 7px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 490px) {
  .cover3-image1 {
    display: none;
  }

  .cover3-image2 {
    height: 100%;
    width: 100%;
    text-align: center;
    margin: auto;
  }
}

.insta1-para {
  font-family: "Raleway", sans-serif;
  font-size: 19px;
  color: white;
}

.badge1 {
  width: 100px;
  height: 100px;
}

/* Slider styling */

.slide-image > img {
  width: 90%;
  margin: auto;
  height: 100%;
  border-radius: 20px;
  transform: scale(1, 1);
  transition: 0.5s;
}

.slide-image > img:hover {
  transform: scale(1.1, 1.1);
}

@media screen and (min-device-width: 0px) and (max-device-width: 451px) {
  .slide-image > img {
    width: 90%;
  }
}

@media screen and (min-device-width: 452px) and (max-device-width: 611px) {
  .slide-image > img {
    width: 75%;
  }
}

@media screen and (min-device-width: 612px) and (max-device-width: 768px) {
  .slide-image > img {
    width: 60%;
  }
}

@media screen and (min-device-width: 769px) and (max-device-width: 991px) {
  .slide-image > img {
    width: 90%;
  }
}

@media screen and (min-device-width: 991px) and (max-device-width: 1199px) {
  .slide-image > img {
    width: 90%;
  }
}

.cover4-card1 {
  padding: 30px;
  height: 100%;
  justify-content: center;
  display: flex;
  align-content: center;
}

.cover4-cards {
  width: 18rem;
  height: 300px;
  font-size: 15px;
  text-align: center;
  border-radius: 5%;
  -webkit-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
}

.borderCards {
  border-radius: 5%;
  height: 300px;
  border-bottom: 6px solid goldenrod;
}

.card-icon {
  text-align: center;
  font-size: 90px;
  color: goldenrod;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1350px) {
  .cover4-cards {
    width: 15rem;
    height: 100%;
  }

  .card-icon {
    font-size: inherit;
  }
}

@media screen and (min-device-width: 1080px) and (max-device-width: 1199px) {
  .cover4-cards {
    width: 12rem;
    height: 300px;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1080px) {
  .cover4-cards {
    width: 12rem;
    height: 300px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .cover4-cards {
    width: 10rem;
    height: 320px;
    font-size: 12px;
  }

  .card-icon > img {
    width: 70px;
    height: 80px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 430px) {
  .cover4-cards {
    width: 28rem !important;
    height: 320px;
    font-size: 17px;
    margin: auto !important;
  }

  .col-card {
    margin: auto !important;
  }

  .cover4-card1 {
    padding: 20px;
    margin: auto;
  }
}

@media screen and (min-device-width: 431px) and (max-device-width: 767px) {
  .cover4-cards {
    width: 28rem !important;
    height: 300px;
    font-size: 20px;
  }
  .col-card {
    padding: 10px;
    justify-content: center;
    display: flex;
    align-content: center;
  }
}

.press > img {
  margin: auto;
  justify-content: center;
  display: flex;
  align-content: center;
  width: 1000px;
}

@media screen and (min-device-width: 768px) and (max-device-width: 3000px) {
  .pressmob > img {
    display: none;
  }
}

@media screen and (min-device-width: 991px) and (max-device-width: 1199px) {
  .press > img {
    margin: auto;
    width: 800px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 990px) {
  .press > img {
    margin: auto;
    width: 600px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 767px) {
  .press > img {
    display: none;
  }
  .pressmob > img {
    width: 100%;
    margin: auto;
  }
}

.cover5 {
  padding-left: 500px;
  padding-right: 500px;
}

.cover5-x > img {
  float: left;
  padding-left: 70px;
  margin-top: -18px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 850px) {
  .cover5-x > img {
    display: none;
  }

  .cover5 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .cover5 > h1 {
    font-size: 30px;
  }
}

@media screen and (min-device-width: 769px) and (max-device-width: 1500px) {
  .cover5 {
    padding-left: 50px;
    padding-right: 50px;
  }
}
/* ############################################## */

.press-card {
  width: 28rem;
  height: 500px;
  margin: auto;
}

.press-col {
  padding: 40px;
  justify-content: center;
  align-content: center;
  display: flex;
}

.press-title {
  padding-top: 40px;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1599px) {
  .press-card {
    width: 28rem;
    margin: auto;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .press-card {
    width: 26rem;
    margin: auto;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .press-card {
    width: 20rem;
    margin: auto;
  }
}

@media screen and (min-device-width: 558px) and (max-device-width: 767px) {
  .press-card {
    width: 28rem !important;
    margin: auto;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 557px) {
  .press-card {
    width: 20rem !important;
    margin: auto;
    height: 400px !important;
  }

  .press-card > img {
    height: 180px;
  }

  .press-title {
    padding-top: 0;
  }
}

.contact {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("./assets/contact.jpg");
}

.contact-container {
  width: 100%;
  margin-top: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white;
  text-align: center;
  border: 5px solid goldenrod;
}

.contact-para {
  padding: 60px;
  font-size: 20px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 557px) {
  .contact-para {
    padding: 20px;
    font-size: 15px;
  }
}

.press-logo1 > img {
  width: 120px;
  height: 30px;
}

.press-logo2 > img {
  width: 120px;
  height: 40px;
}
.press-logo3 > img {
  width: 130px;
  height: 30px;
}
.press-logo4 > img {
  width: 130px;
  height: 40px;
}
.press-logo5 > img {
  width: 140px;
  height: 40px;
}
.press-logo6 > img {
  width: 100px;
  height: 60px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 558px) {
  .press-logo1 > img {
    width: 100px;
    height: 25px;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .press-logo2 > img {
    width: 100px;
    height: 30px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  .press-logo3 > img {
    width: 130px;
    height: 25px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  .press-logo4 > img {
    width: 120px;
    height: 35px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  .press-logo5 > img {
    width: 140px;
    height: 40px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  .press-logo6 > img {
    width: 80px;
    height: 50px;
    margin-bottom: 20px;
  }
}

.extra {
  font-size: 22px;
  font-weight: bold;
  margin: auto;
  text-align: center;
}

@media screen and (min-device-width: 0px) and (max-device-width: 489px) {
  .extra {
    font-size: 18px;
    font-weight: bold;
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 400px) {
  .extra {
    margin-top: -10px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 770px) {
  .extra {
    font-size: 19px;
    font-weight: bold;
    /* margin:auto; */
    text-align: center;
    margin-top: -10px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 890px) {
  .extra {
    font-size: 19px;
    font-weight: bold;
    /* margin:auto; */
    text-align: center;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1281px) {
  .extra {
    font-size: 19px;
    font-weight: bold;
    margin: auto;
    text-align: center;
  }
}

/* ################ */

.extra-x {
  font-size: 22px;
  font-weight: bold;
  margin: auto;
  text-align: center;
}

@media screen and (min-device-width: 0px) and (max-device-width: 489px) {
  .extra-x {
    font-size: 18px;
    font-weight: bold;
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 339px) {
  .extra-x {
    margin-top: -10px;
  }
}

@media screen and (min-device-width: 400px) and (max-device-width: 767px) {
  .extra-x {
    margin-top: 10px;
  }
}

@media screen and (min-device-width: 800px) and (max-device-width: 900px) {
  .extra-x {
    margin-top: 10px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 770px) {
  .extra-x {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 890px) {
  .extra-x {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1281px) {
  .extra-x {
    font-size: 19px;
    font-weight: bold;
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1281px) {
  .extra-x {
    margin-top: 10px;
  }
}

@media screen and (min-device-width: 1281px) and (max-device-width: 1311px) {
  .extra-x {
    margin-top: -10px;
  }
}

/* Footer */

/*FOOTER*/
footer {
  width: 100%;
  background-color: #121619;
  color: #fff;
}

.top_header {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.top_header section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.top_header span {
  padding: 0 1rem;
}

.top_header .fa {
  color: #ffbe00;
  font-size: 35px;
}

footer .border-shape {
  width: 40%;
}

footer .border-shape::before {
  width: 100%;
  left: 120px;
}

footer .border-shape::after {
  width: 100%;
  right: 120px;
}

footer .bottom_content section {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: Center;
}

.bottom_content a {
  margin: 0 20px;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}

.bottom_content a:hover {
  color: rgba(255, 255, 255, 1);
}

.copyright {
  padding: 0.8em 0;
  background-color: #1e1e1e;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

@media (max-width: 820px) {
  .top_header {
    padding: 1rem;
    display: block;
  }

  .top_header section {
    margin: 40px 0;
    align-items: left;
    justify-content: left;
  }
  footer .bottom_content section {
    padding: 1rem;
    display: block;
  }
  footer .bottom_content section a {
    padding: 1rem;
    font-size: 12px;
    margin: 0 5px;
    display: inline-block;
  }
}

/* Footer ends */

.detail p {
  font-size: larger;
  margin: auto;
}

@media screen and (min-device-width: 0px) and (max-device-width: 767px) {
  .detail p {
    font-size: medium;
    text-align: center !important;
  }

  .anime {
    display: none;
  }
}

.ss {
  border: 2px solid black;
}

.linked {
  color: white !important;
  display: flex;
}

a.linked:hover {
  color: goldenrod !important;
}

@media screen and (min-device-width: 320px) and (max-device-width: 370px) {
  .press-cardfont {
    font-size: 15px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 320px) {
  .press-cardfont {
    font-size: 13px;
  }
}

.press-offerCard {
  height: 420px;
}

@media screen and (min-device-width: 991px) and (max-device-width: 1199px) {
  .press-offerCard {
    height: 460px;
  }
}

.process-card {
  height: 200px;
}

.press-offerCard-2 {
  height: 420px;
}

.check {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-device-width: 991px) and (max-device-width: 1199px) {
  .press-offerCard-2 {
    height: 460px;
  }
}

.about {
  color: white;
  text-align: center;
  margin: auto;
  background-color: #343434 !important;
  -webkit-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.29);
}

.slide-video {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  width: 300px;
  height: 50%;
}

.slide-video-x {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  padding-top: 80px;
  width: 50%;
  height: 100%;
}

@media screen and (min-device-width: 0px) and (max-device-width: 991px) {
  .col-card-testimonial {
    padding: 10px;
    justify-content: center;
    display: flex;
    align-content: center;
  }
}

/* Error Page */

#notfound {
  position: relative;
  height: 100vh;
  background-color: #fafbfd;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-bg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

.notfound .notfound-bg > div {
  width: 100%;
  background: #fff;
  border-radius: 90px;
  height: 125px;
}

.notfound .notfound-bg > div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
  box-shadow: 5px 5px 0px 0px #f3f3f3;
}

.notfound .notfound-bg > div:nth-child(2) {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
  box-shadow: 5px 5px 0px 0px #f3f3f3;
  position: relative;
  z-index: 10;
}

.notfound .notfound-bg > div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
  box-shadow: 5px 5px 0px 0px #f3f3f3;
  position: relative;
  z-index: 90;
}

.notfound h1 {
  font-family: "Quicksand", sans-serif;
  font-size: 86px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
  color: #151515;
}

.notfound h2 {
  font-family: "Quicksand", sans-serif;
  font-size: 26px;
  margin: 0;
  font-weight: 700;
  color: #151515;
}

.notfound a {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #18e06f;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}

.notfound-social {
  margin-top: 20px;
}

.notfound-social > a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: #fff;
  background-color: #dedede;
  margin: 3px;
  padding: 0px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound-social > a:hover {
  background-color: #18e06f;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-bg {
    width: 287px;
    margin: auto;
  }

  .notfound .notfound-bg > div {
    height: 85px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound h1 {
    font-size: 68px;
  }

  .notfound h2 {
    font-size: 18px;
  }
}

/* Error Page closed */

/* Additional */

@media screen and (min-device-width: 821px) {
  .footer_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
